/* eslint-disable no-use-before-define */
import React, { useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function ProjectSelection({
  appState,
  project,
  date,
  setDate,
  setProject,
  setAppState,
  projectName,
  setProjectName,
}) {
  const dateInputRef = useRef(null);

  return (
    <div className="projectContainer">
      <h2>Select Project</h2>
      <TextField
        id="date"
        label="Date Data Collected"
        variant="outlined"
        // label="Select Date",

        type="date"
        inputRef={dateInputRef} // Attach ref to the actual DOM input element
        onClick={() => {
          // Use ref to trigger focus programmatically
          if (dateInputRef.current) {
            dateInputRef.current.focus(); // Ensure focus on the input element
            dateInputRef.current.showPicker?.(); // Trigger date picker if supported
          }
        }}
        value={date}
        onChange={(event) => {
          setDate(event.target.value);
        }}
      />
      <TextField
        disabled={appState.projectId}
        value={projectName}
        onChange={(e) => {
          setProjectName(e.target.value);
        }}
        id="outlined-basic"
        label={"New Project Name"}
        variant="outlined"
      />
      <strong
        style={{
          color: "rgba(0, 0, 0, 0.54)",
          textAlign: "center",
        }}
      >
        OR
      </strong>
      <Autocomplete
        fullWidth
        // disabled={projectName}
        id="combo-box-demo"
        options={appState.projects}
        value={project}
        getOptionLabel={(option) => option.name}
        onChange={(a, b, c) => {
          setProjectName("");
          setProject(b);
          setAppState({
            ...appState,
            projectId: b ? b.id : null,
            folderId: null,
            fileId: null,
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Existing Project"
            variant="outlined"
          />
        )}
      />
    </div>
  );
}
