import React from "react";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

const DraggableComp = (props) => {
  const position = { x: window.innerWidth * 0.291, y: 50 };

  return (
    <div>
      <Draggable disabled={props.disabled} defaultPosition={position}>
        {/* <Paper elevation={5}>{props.children}</Paper> */}
        <div>{props.children}</div>
      </Draggable>
    </div>
  );
};

export default DraggableComp;
