import AddBox from "@material-ui/icons/AddBox";
import { mapValues, keyBy } from "lodash";
import pinImage from "../../../../images/qrlogo.png";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable, { MTableAction } from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createShareToken,
  deleteShareToken,
  updateShare,
} from "../../../../../services/user.service";
import { displayMessage } from "./../../../../common/common";
import { DataContext } from "../../../../assets/store/DataContext";
import { Button } from "@material-ui/core";
import { QRCode } from "react-qrcode-logo";
import { Autocomplete } from "@material-ui/lab";
import secureLocalStorage from "react-secure-storage";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function validateField(val) {
  return val != "";
}

const ShareFn = (props) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState("2999-01-01");
  const [embedded, setEmbedded] = useState(false);
  const [edit, setEdit] = useState(false);
  const [zoomTo, setZoomTo] = useState(null);
  const [permission, setPermission] = useState({
    title: "Allowed",
    value: true,
  });
  const [open, setOpen] = React.useState(false);
  const [openQR, setOpenQR] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDate("2999-01-01");
    setZoomTo(null);
    setPermission({
      title: "Allowed",
      value: true,
    });
  };
  const handleCloseQR = () => {
    setOpenQR(false);
  };
  const downloadCode = (id, zoomTo) => {
    const canvas = document.getElementById(id);
    let Names = mapValues(
      keyBy(
        props.appState.layerArray.filter((item) => item.projectId == props.id),
        "id"
      ),
      "name"
    );
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = props.name + "-" + Names[zoomTo] + `.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };
  const columns = [
    {
      title: "Exp Date",
      field: "expirationDate",
      type: "date",
      editable: "onAdd",
      validate: (rowData) => {
        return rowData.expirationDate ? "" : "Date is required";
      },
      cellStyle: window.matchMedia("(max-width: 767px)").matches
        ? {
            display: "none",
            overflowWrap: "anywhere",
          }
        : {
            overflowWrap: "anywhere",
          },
      headerStyle: window.matchMedia("(max-width: 767px)").matches
        ? {
            display: "none",
          }
        : {},
    },
    {
      title: "Zoom to Layer",
      field: "zoomTo",
      validate: (rowData) => {
        return rowData.zoomTo ? "" : "Field is required";
      },
      lookup: mapValues(
        keyBy(
          props.appState.layerArray.filter(
            (item) => item.projectId == props.id
          ),
          "id"
        ),
        "name"
      ),
      cellStyle: {
        overflowWrap: "anywhere",
      },
      // editable: "onUpdate",
    },
    {
      title: "Embed / QR code",
      field: "token",
      editable: "never",
      render: (rowData) => (
        <span
          style={{
            textAlign: "center",
            display: "block",
            width: window.matchMedia("(max-width: 767px)").matches ? 530 : 350,
            zoom: 0.6,
          }}
        >
          <div style={{ display: "none" }}>
            <QRCode
              value={window.location.origin + "/share?token=" + rowData.token}
              size={143}
              id={rowData.token}
              quietZone={2}
              logoImage={pinImage}
              logoHeight={60}
              logoWidth={60}
              // logoImage="/assets/qrlogo.svg"
            />
          </div>
          <Button
            disabled={
              !JSON.parse(secureLocalStorage.getItem("restrictions"))
                ?.shareLinkQr
            }
            variant="contained"
            className="btn-block btnTheme addLink addLink1"
            autoFocus
            style={{
              width: "auto",
              marginRight: 10,
              zoom: window.matchMedia("(max-width: 767px)").matches ? 1.5 : "",
            }}
            onClick={() => {
              setEmbedded(true);
              setOpenQR(rowData.token);
            }}
          >
            Embed link
          </Button>
          <Button
            disabled={
              !JSON.parse(secureLocalStorage.getItem("restrictions"))
                ?.shareLinkQr
            }
            variant="contained"
            className="btn-block btnTheme addLink addLink1"
            autoFocus
            style={{
              width: "auto",
              marginRight: 10,
              zoom: window.matchMedia("(max-width: 767px)").matches ? 1.5 : "",
            }}
            onClick={() => {
              setEmbedded(false);
              setOpenQR(rowData.token);
            }}
          >
            View QR
          </Button>
          <Button
            disabled={
              !JSON.parse(secureLocalStorage.getItem("restrictions"))
                ?.shareLinkQr
            }
            variant="contained"
            className="btn-block btnTheme addLink addLink1"
            autoFocus
            style={{
              width: "auto",
              zoom: window.matchMedia("(max-width: 767px)").matches ? 1.5 : "",
            }}
            onClick={() => {
              downloadCode(rowData.token, rowData.zoomTo);
            }}
          >
            Download QR
          </Button>
        </span>
      ),
      // window.location.origin + "/share?token=" + rowData.token,
    },
    {
      title: "Link",
      field: "token",
      editable: "never",
      cellStyle: window.matchMedia("(max-width: 767px)").matches
        ? {
            display: "none",
          }
        : {},
      headerStyle: window.matchMedia("(max-width: 767px)").matches
        ? {
            display: "none",
          }
        : {},
      render: (rowData) => (
        <div className="tableLink">
          {window.location.origin + "/share?token=" + rowData.token}
        </div>
      ),
    },
    {
      title: "Download Permission",
      field: "download",
      editable: "onAdd",
      cellStyle: window.matchMedia("(max-width: 767px)").matches
        ? {
            display: "none",
          }
        : {},
      headerStyle: window.matchMedia("(max-width: 767px)").matches
        ? {
            display: "none",
          }
        : {},
      lookup: {
        [true]: "Allowed",
        [false]: "Not Allowed",
      },
      render: (rowData) => {
        return rowData.download ? <CheckCircleIcon /> : <CancelIcon />;
      },

      // validate: (rowData) => {
      //   return rowData.download ? "" : "Permission is required";
      // },
    },
  ];

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ block: "center" });
  }, []);
  const addActionRef = React.useRef();
  const scrollRef = React.useRef();
  return (
    <>
      <Dialog
        open={openQR}
        onClose={handleCloseQR}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent
          style={{
            textAlign: "center",
          }}
        >
          {embedded ? (
            <textarea
              style={{ width: 300, minHeight: 100 }}
              value={
                '<iframe src="' +
                window.location.origin +
                "/share?token=" +
                openQR +
                '"width="100%" height="1024" title="MetaGeo"></iframe>'
              }
              disabled
            />
          ) : (
            <QRCode
              value={window.location.origin + "/share?token=" + openQR}
              size={143}
              quietZone={2}
              logoImage={pinImage}
              logoHeight={60}
              logoWidth={60}
              // logoImage="/assets/qrlogo.svg"
            />
          )}
          <DialogActions style={{ placeContent: "center" }}>
            <Button onClick={handleCloseQR} color="primary">
              Close
            </Button>
            {embedded && (
              <Button
                onClick={() => {
                  var copyText =
                    '<iframe src="' +
                    window.location.origin +
                    "/share?token=" +
                    openQR +
                    '"width="100%" height="1024" title="MetaGeo"></iframe>';
                  navigator.clipboard.writeText(copyText);
                  dispatch(
                    displayMessage("success", "Embed Link copied to clipboard")
                  );
                }}
                color="primary"
              >
                Copy
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {edit ? "Update Share Link" : "Create Share Link"}
        </DialogTitle>
        <DialogContent>
          <TextField
            disabled={edit}
            id="date"
            variant="outlined"
            fullWidth
            label="Link Expiration Date"
            type="date"
            onChange={(e) => {
              setDate(e.target.value);
            }}
            style={{
              padding: "0px 10px 15px 10px",
            }}
            value={date}
            InputLabelProps={{
              shrink: true,
            }}
          />{" "}
          <Autocomplete
            disableClearable
            id="combo-box-demo"
            fullWidth
            value={zoomTo}
            onChange={(a, b, c) => {
              setZoomTo(b);
            }}
            options={props.appState.layerArray.filter(
              (item) => item.projectId == props.id
            )}
            getOptionLabel={(option) => option.name}
            style={{ minWidth: 300, padding: "0px 10px 15px 10px" }}
            renderInput={(params) => (
              <TextField {...params} label="Zoom to layer" variant="outlined" />
            )}
          />
          <Autocomplete
            disabled={edit}
            disableClearable
            fullWidth
            onChange={(a, b, c) => {
              setPermission(b);
            }}
            value={permission}
            id="combo-box-demo"
            options={[
              {
                title: "Allowed",
                value: true,
              },
              { title: "Not Allowed", value: false },
            ]}
            getOptionLabel={(option) => option.title}
            style={{ minWidth: 300, padding: "0px 10px 15px 10px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Enable Download"
                variant="outlined"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (!zoomTo) {
                dispatch(displayMessage("error", "Please choose a layer."));
              } else {
                if (edit) {
                  updateShare({
                    shareToken: edit.token,
                    projectId: props.id,
                    layerId: zoomTo.id,
                  }).then((res) => {
                    const dataUpdate = [...props.tokens];
                    const index = edit.tableData.id;
                    let temp = edit;
                    temp.zoomTo = zoomTo.id;
                    dataUpdate[index] = temp;
                    props.setTokens([...dataUpdate]);
                    dispatch(
                      displayMessage("success", "Link updated successfully")
                    );
                    handleClose();
                  });
                } else {
                  createShareToken(
                    props.id,
                    date + "T00:00:00.000Z",
                    zoomTo.id,
                    permission.value
                  ).then((res) => {
                    let temp = {
                      token: res.data.token,
                      download: permission.value,
                      expirationDate: date + "T00:00:00.000Z",
                      zoomTo: zoomTo.id,
                    };
                    props.setTokens([...props.tokens, temp]);
                    dispatch(
                      displayMessage(
                        "success",
                        "Share link created successfully"
                      )
                    );
                    handleClose();
                  });
                }
              }
            }}
            color="primary"
          >
            {edit ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Button
        onClick={() => addActionRef.current.click()}
        style={{ marginBlock: 12 }}
        variant="contained"
        className="btn-block btnTheme"
        autoFocus
      >
        Add Link
      </Button> */}
      <div className="sharelinkTable">
        <MaterialTable
          components={{
            Action: (props) => {
              //If isn't the add action
              if (
                typeof props.action === typeof Function ||
                props.action.tooltip !== "Add"
              ) {
                return <MTableAction {...props} />;
              } else {
                return (
                  <div ref={addActionRef} onClick={props.action.onClick} />
                );
              }
            },
          }}
          style={{ zoom: "88%" }}
          localization={{
            body: {
              deleteTooltip: "Deactivate",
            },
          }}
          actions={[
            {
              icon: EditIcon,
              // disabled:rowData.isVerified?true:false,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                setEdit(rowData);
                setDate(rowData.expirationDate.split("T")[0]);
                setZoomTo(
                  props.appState.layerArray.filter(
                    (item) => item.id == Number(rowData.zoomTo)
                  )[0]
                );
                setPermission(
                  rowData.download
                    ? {
                        title: "Allowed",
                        value: true,
                      }
                    : { title: "Not Allowed", value: false }
                );
                handleClickOpen();
              },
            },
            {
              icon: FileCopyIcon,
              // disabled:rowData.isVerified?true:false,
              tooltip: "Copy to Clipboard",
              onClick: (event, rowData) => {
                var copyText =
                  window.location.origin + "/share?token=" + rowData.token;
                navigator.clipboard.writeText(copyText);
                dispatch(displayMessage("success", "Link copied to clipboard"));
              },
            },
          ]}
          options={{
            exportButton: true,
            exportAllData: true,
          }}
          icons={tableIcons}
          title={
            <div>
              <h6
                class="MuiTypography-root MuiTypography-h6"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "inline",
                }}
              >
                Share Links
              </h6>
              <Button
                onClick={() => {
                  setEdit(false);
                  handleClickOpen();
                  // addActionRef.current.click();
                  // setTimeout(() => {
                  //   if (
                  //     document.querySelectorAll(
                  //       ".sharingPanel .MuiTableCell-alignLeft:nth-of-type(4) .MuiInput-root .MuiInput-input span"
                  //     ).length
                  //   )
                  //     document.querySelectorAll(
                  //       ".sharingPanel .MuiTableCell-alignLeft:nth-of-type(3) .MuiInput-root .MuiInput-input span"
                  //     )[0].innerHTML = "Select zoom to Layer";
                  //   document.querySelectorAll(
                  //     ".sharingPanel .MuiTableCell-alignLeft:nth-of-type(4) .MuiInput-root .MuiInput-input span"
                  //   )[0].innerHTML = "Select permission";
                  // }, 1);
                }}
                style={{ margin: 10, width: 125 }}
                variant="contained"
                ref={scrollRef}
                className="btn-block btnTheme addLink"
                autoFocus
              >
                Add Link
              </Button>
            </div>
          }
          columns={columns}
          data={props.tokens}
          editable={{
            onRowAdd: (newData) => {
              return new Promise((resolve, reject) => {
                if (newData.expirationDate != "") {
                  createShareToken(
                    props.id,
                    newData.expirationDate,
                    newData.zoomTo,
                    JSON.parse(newData.download)
                  ).then((res) => {
                    newData["token"] = res.data.token;
                    //   newData["uuid"] = res.data.user.uuid;
                    //   newData["isVerified"] = 0;
                    //   newData["typeID"] = 2;
                    newData.download = JSON.parse(newData.download);
                    props.setTokens([...props.tokens, newData]);

                    resolve();
                    dispatch(
                      displayMessage(
                        "success",
                        "Share link created successfully"
                      )
                    );
                  });
                } else {
                  reject();
                }
              });
            },
            // onRowUpdate: (newData, oldData) => {
            //   return new Promise((resolve, reject) => {
            //     if (newData.zoomTo) {
            //       try {
            //         updateShare({
            //           shareToken: newData.token,
            //           projectId: props.id,
            //           layerId: newData.zoomTo,
            //         }).then((res) => {
            //           const dataUpdate = [...props.tokens];
            //           const index = oldData.tableData.id;
            //           dataUpdate[index] = newData;
            //           props.setTokens([...dataUpdate]);
            //           resolve();
            //           dispatch(
            //             displayMessage("success", "Link updated successfully")
            //           );
            //         });
            //       } catch (error) {
            //         dispatch(displayMessage("error", "Error in updating link"));
            //       }
            //     } else {
            //       reject();
            //     }
            //   });
            // },
            onRowDelete: window.matchMedia("(max-width: 767px)").matches
              ? null
              : (oldData) =>
                  new Promise((resolve, reject) => {
                    deleteShareToken(props.id, oldData.token).then((res) => {
                      const dataDelete = [...props.tokens];
                      const index = oldData.tableData.id;
                      dataDelete.splice(index, 1);
                      props.setTokens([...dataDelete]);
                      resolve();
                      dispatch(
                        displayMessage("success", "Link deleted successfully")
                      );
                    });
                  }),
          }}
        />
      </div>
    </>
  );
};

const ShareBox = (props) => (
  <DataContext.Consumer>
    {(mainConsumer) => <ShareFn {...mainConsumer} {...props} />}
  </DataContext.Consumer>
);

export default ShareBox;
