import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ProjectSelection from "./components/ProjectSelection";
import FolderUploadTab from "./components/FolderUploadTab";
let openAccordiansUploader = [];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 300,
    width: 800,
  },
  tabs: {
    "& .MuiTabs-fixed": {
      background: "rgb(144, 29, 161)",
      color: "white",
    },
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function UploadWizard({
  step,
  appState,
  setAppState,
  allFolders,
  filePath,
  setFilePath,
  wizardUploaderFiles,
  setWizardUploaderFiles,
  projectName,
  setProjectName,
  date,
  setDate,
  project,
  setProject,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [folder, setFolder] = React.useState(null);
  const [folderName, setFolderName] = React.useState("");
  const [failedUploads, setFailedUploads] = React.useState([]);
  const [doneUploads, setDoneUploads] = React.useState([]);
  const [newUploadFiles, setNewUploadfiles] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={"wizardContainer"}>
      {step == 1 ? (
        <>
          <ProjectSelection
            projectName={projectName}
            setProjectName={setProjectName}
            project={project}
            date={date}
            setDate={setDate}
            setProject={setProject}
            appState={appState}
            setAppState={setAppState}
          />
        </>
      ) : null}
      {step == 2 ? (
        <>
          <Tabs
            orientation="vertical"
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs + " tabsMobile"}
          >
            <Tab label="Images" {...a11yProps(0)} />
            <Tab label="Vectors (points, lines, polygons)" {...a11yProps(1)} />
            <Tab label="Raster Maps and DEMs" {...a11yProps(2)} />
            <Tab label="Point Clouds and 3D Models" {...a11yProps(3)} />
            <Tab label="Bulk Upload" {...a11yProps(4)} />
            <Tab label="Documents" {...a11yProps(5)} />
          </Tabs>
          <TabPanel className={"mobileTabpanel"} value={value} index={0}>
            <FolderUploadTab
              handleChange={handleChange}
              accepts={".png,.jpeg,.jpg"}
              tab={value}
              projectName={projectName}
              setProjectName={setProjectName}
              wizardUploaderFiles={wizardUploaderFiles}
              setWizardUploaderFiles={setWizardUploaderFiles}
              setFilePath={setFilePath}
              appState={appState}
              folderName={folderName}
              setFolderName={setFolderName}
              allFolders={allFolders}
              folder={folder}
              setFolder={setFolder}
              setAppState={setAppState}
              openAccordiansUploader={openAccordiansUploader}
              failedUploads={failedUploads}
              doneUploads={doneUploads}
              setDoneUploads={setDoneUploads}
              filePath={filePath}
              newUploadFiles={newUploadFiles}
              setNewUploadfiles={(newfiles) => {
                setNewUploadfiles(newfiles);
              }}
            />
          </TabPanel>
          <TabPanel className={"mobileTabpanel"} value={value} index={1}>
            <FolderUploadTab
              handleChange={handleChange}
              tab={value}
              accepts={
                ".kml,.zip,.kmz,.KML,.KMZ,.dxf,.json,.gpx,.DXF,.JSON,.GPX,.geojson,.GEOJSON"
              }
              projectName={projectName}
              setProjectName={setProjectName}
              wizardUploaderFiles={wizardUploaderFiles}
              setWizardUploaderFiles={setWizardUploaderFiles}
              setFilePath={setFilePath}
              appState={appState}
              folderName={folderName}
              setFolderName={setFolderName}
              allFolders={allFolders}
              folder={folder}
              setFolder={setFolder}
              setAppState={setAppState}
              openAccordiansUploader={openAccordiansUploader}
              failedUploads={failedUploads}
              doneUploads={doneUploads}
              setDoneUploads={setDoneUploads}
              filePath={filePath}
              newUploadFiles={newUploadFiles}
              setNewUploadfiles={(newfiles) => {
                setNewUploadfiles(newfiles);
              }}
            />
          </TabPanel>
          <TabPanel className={"mobileTabpanel"} value={value} index={2}>
            <FolderUploadTab
              handleChange={handleChange}
              tab={value}
              accepts={
                ".tiff,.tif,.geotif,.geotiff,.TIFF,.TIF,.GEOTIF,.GEOTIFF"
              }
              projectName={projectName}
              setProjectName={setProjectName}
              wizardUploaderFiles={wizardUploaderFiles}
              setWizardUploaderFiles={setWizardUploaderFiles}
              setFilePath={setFilePath}
              appState={appState}
              folderName={folderName}
              setFolderName={setFolderName}
              allFolders={allFolders}
              folder={folder}
              setFolder={setFolder}
              setAppState={setAppState}
              openAccordiansUploader={openAccordiansUploader}
              failedUploads={failedUploads}
              doneUploads={doneUploads}
              setDoneUploads={setDoneUploads}
              filePath={filePath}
              newUploadFiles={newUploadFiles}
              setNewUploadfiles={(newfiles) => {
                setNewUploadfiles(newfiles);
              }}
            />
          </TabPanel>
          <TabPanel className={"mobileTabpanel"} value={value} index={3}>
            <FolderUploadTab
              handleChange={handleChange}
              tab={value}
              accepts={".zip,.las,.LAS,.laz,.LAZ"}
              projectName={projectName}
              setProjectName={setProjectName}
              wizardUploaderFiles={wizardUploaderFiles}
              setWizardUploaderFiles={setWizardUploaderFiles}
              setFilePath={setFilePath}
              appState={appState}
              folderName={folderName}
              setFolderName={setFolderName}
              allFolders={allFolders}
              folder={folder}
              setFolder={setFolder}
              setAppState={setAppState}
              openAccordiansUploader={openAccordiansUploader}
              failedUploads={failedUploads}
              doneUploads={doneUploads}
              setDoneUploads={setDoneUploads}
              filePath={filePath}
              newUploadFiles={newUploadFiles}
              setNewUploadfiles={(newfiles) => {
                setNewUploadfiles(newfiles);
              }}
            />
          </TabPanel>
          <TabPanel className={"mobileTabpanel"} value={value} index={4}>
            <FolderUploadTab
              handleChange={handleChange}
              tab={value}
              accepts={
                ".tiff,.tif,.geotif,.geotiff,.kml,.zip,.png,.jpeg,.kmz,.KML,.KMZ,.TIFF,.TIF,.GEOTIF,.GEOTIFF,.las,.LAS,.laz,.LAZ,.jpg,.dxf,.json,.gpx,.DXF,.JSON,.GPX,.geojson,.GEOJSON,.pdf,.txt,.csv,.docx,.xlsx,.doc"
              }
              projectName={projectName}
              setProjectName={setProjectName}
              wizardUploaderFiles={wizardUploaderFiles}
              setWizardUploaderFiles={setWizardUploaderFiles}
              setFilePath={setFilePath}
              appState={appState}
              folderName={folderName}
              setFolderName={setFolderName}
              allFolders={allFolders}
              folder={folder}
              setFolder={setFolder}
              setAppState={setAppState}
              openAccordiansUploader={openAccordiansUploader}
              failedUploads={failedUploads}
              doneUploads={doneUploads}
              setDoneUploads={setDoneUploads}
              filePath={filePath}
              newUploadFiles={newUploadFiles}
              setNewUploadfiles={(newfiles) => {
                setNewUploadfiles(newfiles);
              }}
            />
          </TabPanel>
          <TabPanel className={"mobileTabpanel"} value={value} index={5}>
            <FolderUploadTab
              handleChange={handleChange}
              tab={value}
              accepts={
                ".pdf,.txt,.csv,.docx,.xlsx,.doc"
              }
              projectName={projectName}
              setProjectName={setProjectName}
              wizardUploaderFiles={wizardUploaderFiles}
              setWizardUploaderFiles={setWizardUploaderFiles}
              setFilePath={setFilePath}
              appState={appState}
              folderName={folderName}
              setFolderName={setFolderName}
              allFolders={allFolders}
              folder={folder}
              setFolder={setFolder}
              setAppState={setAppState}
              openAccordiansUploader={openAccordiansUploader}
              failedUploads={failedUploads}
              doneUploads={doneUploads}
              setDoneUploads={setDoneUploads}
              filePath={filePath}
              newUploadFiles={newUploadFiles}
              setNewUploadfiles={(newfiles) => {
                setNewUploadfiles(newfiles);
              }}
            />
          </TabPanel>
        </>
      ) : null}
    </div>
  );
}
