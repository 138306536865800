import { Box, Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DropzoneArea } from "material-ui-dropzone";
import React from "react";
import Carousel from "react-material-ui-carousel";
import { Link } from "react-router-dom";
import InputValidator from "../../../InputValidator";
import WMSDialog from "./WMSDialog";
import secureLocalStorage from "react-secure-storage";
import SharingTabs from "./SharingDialog";
import UploadWizard from "./UploadWizard";
const DialogComp = (props) => {
  const {
    deleteDrawer,
    allFolders,
    filePath,
    setFilePath,
    Transition,
    handleCloseDeleteDialog,
    displayItems,
    handleUploadClose,
    setFolderfiles,
    setNewUploadfiles,
    errorDisplay,
    appState,
    setAppState,
    handleUploadWizard,
    newUploadFiles,
    handleDrawerUploadOk,
    uploadMediaDrawer,
    handleMediaUploadClose,
    classes,
    infoLayerSelectedMedia,
    dispatch,
    handleMediaFilesChange,
    newMediaUploadFiles,
    handleMediaDrawerUploadOk,
    handleCloseFolderDialog,
    handleSubmitFolder,
    handleSubmitEdit,
    handleCloseEditDialog,
    handleSharingDialog,
    handleCloseProjectDialog,
    handleSubmitProject,
    tipsDialog,
    handleCloseTipsDialog,
    LOADING_MESSAGE,
    SET_MESSAGE,
    disableTips,
    eulaDialog,
    handleCloseEulaDialog,
    enableEula,
    setEulaDialog,
    storageDialog,
    setStorageDialog,
    addWMSDialog,
    setAddWMSDialog,
    uploadWmsLayerFn,
    dateCollectedDrawer,
    handleDateCollectedDrawer,
    wizardUploaderFiles,
    setWizardUploaderFiles,
    date,
    setDate,
    project,
    setProject,
  } = props;
  const [projectName, setProjectName] = React.useState("");

  return (
    <>
      <Dialog
        open={deleteDrawer}
        TransitionComponent={Transition}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseDeleteDialog} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dateCollectedDrawer.open}
        TransitionComponent={Transition}
        onClose={() => {
          handleDateCollectedDrawer({
            ...dateCollectedDrawer,
            open: false,
            confirm: false,
          });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Changing the folder's collection date will automatically update the
            date for all its contained layers.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDateCollectedDrawer({
                ...dateCollectedDrawer,
                open: false,
                confirm: false,
              });
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDateCollectedDrawer({
                ...dateCollectedDrawer,
                open: false,
                confirm: true,
              });
            }}
            color="primary"
            autoFocus
          >
            Change
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        className="uploadDialog"
        open={displayItems.openFileUploader}
        onClose={handleUploadClose}
        style={{ zIndex: 1250 }}
        TransitionComponent={Transition}
      >
        <DialogTitle id="form-dialog-title">Upload file</DialogTitle>
        <DialogContent>
          <input
            type="file"
            id="files"
            name="files"
            accept={
              ".tiff,.tif,.geotif,.geotiff,.kml,.zip,.png,.jpeg,.kmz,.KML,.KMZ,.TIFF,.TIF,.GEOTIF,.GEOTIFF,.las,.LAS,.laz,.LAZ,.jpg"
            }
            multiple
            onChange={(event) => {
              let files = [...event.target.files];
              setFolderfiles([]);
              let restrictedFiles = [];
              files.map((file) => {
                const filenameLow = file.name.toLowerCase();
                const filenameRep = file.name.replace(/\s+/g, "_");

                let format = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/;
                let check = format.test(filenameRep);
                console.log("File NAME Check", check);
                if (
                  filenameLow.includes(".kmz") ||
                  filenameLow.includes(".tif") ||
                  filenameLow.includes(".zip") ||
                  filenameLow.includes(".kml") ||
                  filenameLow.includes(".png") ||
                  filenameLow.includes(".jpeg") ||
                  filenameLow.includes(".jpg") ||
                  filenameLow.includes(".las") ||
                  filenameLow.includes(".laz")
                ) {
                  if (!check) {
                    const tempFile = new File([file], filenameRep, {
                      type: file.type,
                    });

                    // console.log("temp", tempFile, "file", file);
                    restrictedFiles.push(tempFile);
                  }
                }
              });

              setNewUploadfiles(restrictedFiles);
              if (files.length !== restrictedFiles.length) {
                if (restrictedFiles.length === 0) {
                  document.getElementById("files").value = "";
                }
                errorDisplay(
                  "error",
                  `${files.length -
                    restrictedFiles.length} Invalid file name characters or format`
                );
              }
            }}
          ></input>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUploadClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              let totalSize = 0;
              let availableSize = Number(
                secureLocalStorage.getItem("availableStorage")
              );
              newUploadFiles.map((file) => {
                totalSize = totalSize + file.size / 1024 / 1024;
              });

              if (totalSize < availableSize) {
                if (newUploadFiles.length >= 1) {
                  handleDrawerUploadOk();
                } else {
                  errorDisplay("error", "Please select file");
                }
              } else {
                errorDisplay("error", "You don't have storage");
              }
            }}
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        className="uploadDialog"
        open={uploadMediaDrawer}
        onClose={handleMediaUploadClose}
        style={{ zIndex: 1250 }}
        TransitionComponent={Transition}
      >
        <DialogTitle id="form-dialog-title">Upload Media</DialogTitle>
        <DialogContent>
          <DropzoneArea
            // showPreviews={true}
            showPreviewsInDropzone={true}
            // useChipsForPreview
            previewGridProps={{ container: { spacing: 1, direction: "row" } }}
            previewChipProps={{ classes: { root: classes.previewChip } }}
            previewText="Selected files"
            acceptedFiles={["image/*"]}
            filesLimit={5 - infoLayerSelectedMedia.length}
            maxFileSize={104857600}
            onDrop={() => {
              dispatch({ type: LOADING_MESSAGE, isLoading: true });
            }}
            onChange={handleMediaFilesChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMediaUploadClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              // ;
              // newMediaUploadFiles;
              let totalSize = 0;
              let availableSize = Number(
                secureLocalStorage.getItem("availableStorage")
              );
              newMediaUploadFiles.map((file) => {
                totalSize = totalSize + file.size / 1024 / 1024;
              });
              if (
                totalSize < availableSize &&
                newMediaUploadFiles.length >= 1
              ) {
                handleMediaDrawerUploadOk();
              }
            }}
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        TransitionComponent={Transition}
        open={displayItems.openFolderDialog}
        onClose={handleCloseFolderDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add folder</DialogTitle>
        <InputValidator
          label={"Folder Name"}
          name={"name"}
          validators={["required", "matchRegexp:^[A-Za-z0-9_.\\s]+$"]}
          errorMessages={[
            "Folder name is required",
            "Special Chrachters are not allowed",
          ]}
          handleSubmitEdit={handleSubmitFolder}
          handleCloseEditDialog={handleCloseFolderDialog}
          displayItemsName={displayItems.name}
        />
      </Dialog>
      <Dialog
        maxWidth={800}
        TransitionComponent={Transition}
        open={displayItems.openSharingDialog}
        onClose={handleSharingDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {"Share Project: " + displayItems.name}{" "}
          {handleSharingDialog ? (
            <IconButton
              style={{ float: "right", top: -7, right: -16 }}
              aria-label="close"
              //  className={classes.closeButton}
              onClick={handleSharingDialog}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
          <h7 style={{ display: "block", fontSize: "14px", color: "red" }}>
            This will share the project and all folders/files within it
          </h7>
        </DialogTitle>
        <SharingTabs
          style={{
            width: "calc(100% - 480px)",
          }}
          name={displayItems.name}
          id={displayItems.id}
          errorDisplay={errorDisplay}
        />
      </Dialog>
      <Dialog
        maxWidth={800}
        TransitionComponent={Transition}
        open={displayItems.openUploadWizard}
        onClose={() => handleUploadWizard(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {displayItems.openUploadWizard == 1
            ? "Create / Upload New Project"
            : `Uploading in ${project?.name}`}
          {handleUploadWizard ? (
            <IconButton
              style={{ float: "right", top: -7, right: -16 }}
              aria-label="close"
              //  className={classes.closeButton}
              onClick={() => handleUploadWizard(false)}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <UploadWizard
          project={project}
          setProject={setProject}
          date={date}
          setDate={setDate}
          projectName={projectName}
          setProjectName={setProjectName}
          wizardUploaderFiles={wizardUploaderFiles}
          setWizardUploaderFiles={setWizardUploaderFiles}
          allFolders={allFolders}
          filePath={filePath}
          setFilePath={setFilePath}
          setAppState={setAppState}
          appState={appState}
          step={displayItems.openUploadWizard}
        />
        <DialogActions>
          {displayItems.openUploadWizard == 2 ? (
            <Box
              sx={{ mt: 2 }}
              style={{
                left: "12px",
                position: "absolute",
                bottom: 5,
              }}
              className="TitleSection"
            >
              <h5>
                Click here to check{" "}
                <a
                  href="https://support.metageo.io/support/solutions/articles/150000084137-what-file-types-does-metageo-support-"
                  target={"_blank"}
                >
                  Supported FIles
                </a>
              </h5>
            </Box>
          ) : null}
          <Button
            onClick={() => {
              if (displayItems.openUploadWizard == 1) {
                handleUploadWizard(false);
              } else {
                handleUploadWizard(1);
              }
            }}
            color="primary"
          >
            {displayItems.openUploadWizard == 1 ? "Close" : "Back"}
          </Button>
          <Button
            disabled={
              displayItems.openUploadWizard == 1
                ? !appState.projectId && !projectName
                : false
            }
            onClick={() => {
              if (displayItems.openUploadWizard == 1) {
                if (projectName) {
                  handleSubmitProject(projectName, (newProject) => {
                    setProject(newProject);
                  });
                  setProjectName("");
                } else {
                  handleUploadWizard(2);
                }
              } else {
                handleUploadWizard(false);
              }
            }}
            color="primary"
            autoFocus
          >
            {displayItems.openUploadWizard == 1 ? "Next" : "Done"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        TransitionComponent={Transition}
        open={displayItems.openEditDialog}
        onClose={handleCloseEditDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Rename</DialogTitle>

        <InputValidator
          label={"Name"}
          name={"name"}
          validators={["required", "matchRegexp:^[A-Za-z0-9-_.\\s]+$"]}
          errorMessages={[
            "Name is required",
            "Special Chrachters are not allowed",
          ]}
          handleSubmitEdit={handleSubmitEdit}
          handleCloseEditDialog={handleCloseEditDialog}
          displayItemsName={displayItems.name}
        />
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        open={displayItems.openProjectDialog}
        onClose={handleCloseProjectDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add project</DialogTitle>
        <InputValidator
          label={"Project Name"}
          name={"name"}
          validators={["required", "matchRegexp:^[A-Za-z0-9_.\\s]+$"]}
          errorMessages={[
            "Project name is required",
            "Special Chrachters are not allowed",
          ]}
          handleSubmitEdit={handleSubmitProject}
          handleCloseEditDialog={handleCloseProjectDialog}
          displayItemsName={displayItems.name}
        />
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        open={tipsDialog}
        onClose={handleCloseTipsDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Tooltips
          {handleCloseTipsDialog ? (
            <IconButton
              style={{ float: "right" }}
              aria-label="close"
              //  className={classes.closeButton}
              onClick={handleCloseTipsDialog}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Carousel
            className="SecondExample"
            autoPlay={false}
            navButtonsWrapperProps={{
              style: { height: "60%", marginBlock: "10%" },
            }}
            animation={"slide"}
            indicators={true}
            timeout={500}
            interval={8000}
            navButtonsAlwaysVisible={false}
            navButtonsAlwaysInvisible={false}
          >
            {Array.isArray(JSON.parse(secureLocalStorage.getItem("userTips")))
              ? [
                  <iframe
                    width="530"
                    height="300"
                    src="https://www.youtube.com/embed/z4oZumTY4us?rel=0"
                    title="Getting Started with METAGEO"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen={true}
                  ></iframe>,
                  ...JSON.parse(secureLocalStorage.getItem("userTips"))?.map(
                    (item, index) => {
                      return (
                        <Paper
                          key={`UserTips#${index + 1}`}
                          className="projectTip"
                        >
                          <h2 style={{ color: "white" }}>{item.header}</h2>
                          <h4 style={{ marginBottom: 40, color: "white" }}>
                            {item.details}
                          </h4>
                        </Paper>
                      );
                    }
                  ),
                ]
              : [
                  <iframe
                    width="530"
                    height="300"
                    src="https://www.youtube.com/embed/z4oZumTY4us?rel=0"
                    title="Getting Started with METAGEO"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen={true}
                  ></iframe>,
                ]}
          </Carousel>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(a, b, c) => {
                  if (b) {
                    dispatch({ type: LOADING_MESSAGE, isLoading: true });
                    disableTips().then(() => {
                      secureLocalStorage.setItem(
                        "showTips",
                        JSON.stringify(false)
                      );
                      dispatch({
                        type: SET_MESSAGE,
                        payload: {
                          message: "Tips Disabled",
                          severity: "success",
                          isOpen: true,
                        },
                      });
                      handleCloseTipsDialog();
                    });
                  }
                }}
              />
            }
            label={"Don't show this again"}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        open={eulaDialog}
        onClose={handleCloseEulaDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          EULA Updated
          {handleCloseEulaDialog ? (
            <IconButton
              style={{ float: "right" }}
              aria-label="close"
              //  className={classes.closeButton}
              onClick={handleCloseEulaDialog}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <FormControlLabel
            style={{ paddingBottom: 20 }}
            control={
              <Checkbox
                onChange={(a, b, c) => {
                  if (b) {
                    dispatch({ type: LOADING_MESSAGE, isLoading: true });
                    enableEula().then(() => {
                      secureLocalStorage.setItem("eula", JSON.stringify(true));
                      dispatch({
                        type: SET_MESSAGE,
                        payload: {
                          message: "EULA Accepted",
                          severity: "success",
                          isOpen: true,
                        },
                      });
                      setEulaDialog(false);
                    });
                  }
                }}
              />
            }
            label={
              <>
                I certify that I am over the age of 18, and agree to the{" "}
                <Link target={"_blank"} to="/terms-of-service">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <a
                  target={"_blank"}
                  href="https://www.metageo.io/privacy-policy"
                >
                  Privacy Policy
                </a>
              </>
            }
          />
        </DialogContent>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        open={storageDialog}
        onClose={() => {
          setStorageDialog(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Out of Storage
          {handleCloseEulaDialog ? (
            <IconButton
              style={{ float: "right" }}
              aria-label="close"
              //  className={classes.closeButton}
              onClick={() => {
                setStorageDialog(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Hi, It looks like you’re almost out of storage, if you would like to
            increase your storage, please visit your user profile and{" "}
            <a target={"_blank"} href="https://metageo.io/contact-us/">
              upgrade
            </a>
            , or contact&nbsp;
            <a href="sales@metageo.io">sales@metageo.io</a>
          </DialogContentText>
          <DialogContentText>
            <div style={{ fontWeight: "600", fontSize: "90%" }}>
              -METAGEO Team
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <WMSDialog
        transition={Transition}
        addWMSDialog={addWMSDialog}
        setAddWMSDialog={setAddWMSDialog}
        // uploadWMSLayer={uploadWMSLayer}
        uploadWmsLayerFn={uploadWmsLayerFn}
      />
    </>
  );
};

export default DialogComp;
