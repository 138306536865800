/* eslint-disable no-use-before-define */
import React, { useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MetaWizardUploader from "../../Uploader/WizardUploader";
import { findPath } from "../../../../../common/common";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export default function FolderUploadTab({
  appState,
  folderName,
  setFolderName,
  allFolders,
  folder,
  setFolder,
  setAppState,
  openAccordiansUploader,
  failedUploads,
  doneUploads,
  newUploadFiles,
  handleChange,
  setNewUploadfiles,
  setDoneUploads,
  filePath,
  setFilePath,
  wizardUploaderFiles,
  setWizardUploaderFiles,
  accepts,
  tab,
}) {
  const setFolderFunc = (val) => {
    let thisProjectFolders = allFolders.filter(
      (folder) => folder.projectId == appState.projectId
    );
    if (
      thisProjectFolders.filter((folder) =>
        folder.name.toLowerCase().includes(val)
      ).length
    ) {
      setFolderName("");
      setFolder(
        thisProjectFolders.filter((folder) =>
          folder.name.toLowerCase().includes(val)
        )[0]
      );
      setAppState({
        ...appState,
        folderId: thisProjectFolders.filter((folder) =>
          folder.name.toLowerCase().includes(val)
        )[0].id,
        fileId: null,
      });
    } else {
      setFolderName(val);
      setFolder(null);
      setAppState({
        ...appState,
        folderId: null,
        fileId: null,
      });
    }
  };
  useEffect(() => {
    switch (tab) {
      case 0:
        setFolderFunc("images");
        break;
      case 1:
        setFolderFunc("vectors");
        break;
      case 2:
        setFolderFunc("maps");
        break;
      case 3:
        setFolderFunc("3D");
        break;
      case 4:
        setFolderFunc("data");
        break;
      case 5:
        setFolderFunc("docs");
        break;
      default:
        break;
    }
  }, [tab]);
  return (
    <div className="projectContainer">
      <FormControl variant="outlined" className={"tabsMobileSelect"}>
        <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={tab}
          onChange={(event) => {
            handleChange(event, event.target.value);
          }}
          label="Type"
        >
          <MenuItem value={0}>Images</MenuItem>
          <MenuItem value={1}>Vectors (points, lines, polygons)</MenuItem>
          <MenuItem value={2}>Raster Maps and DEMs</MenuItem>
          <MenuItem value={3}>Point Clouds and 3D Models</MenuItem>
          <MenuItem value={4}>Bulk Upload</MenuItem>
          <MenuItem value={5}>Documents</MenuItem>
        </Select>
      </FormControl>
      <h5>
        {tab == 0
          ? "Upload images with location and orientation, supported files: .jpg, .jpeg, .png. Note: .jpg maps are not supported."
          : ""}
        {tab == 1
          ? "Upload vector files, supported files: .kml, .kmz, .shp, .dxf, .json, .gpx, .geojson, (must zip all files including .shx first)"
          : ""}
        {tab == 2
          ? "Upload vector files, supported files: .tif, .tiff, .geotif, .geotiff (Note: .jpg maps are not supported)"
          : ""}
        {tab == 3
          ? "Upload 3D files, supported files: .las, .laz and 3D tiles (must add .json and tile folders into .zip first)"
          : ""}
        {tab == 5
          ? "Upload documents, supported files: .pdf, .txt, .csv, .docx, .xlsx, .doc"
          : ""}
        {tab == 4 ? "" : ""}
      </h5>
      <TextField
        disabled={appState.folderId}
        value={folderName}
        onChange={(e) => {
          setFolderName(e.target.value);
        }}
        id="outlined-basic"
        label={"New Folder Name"}
        variant="outlined"
      />
      <strong
        style={{
          color: "rgba(0, 0, 0, 0.54)",
          textAlign: "center",
        }}
      >
        OR
      </strong>
      <Autocomplete
        fullWidth
        // disabled={folderName}
        id="combo-box-demo"
        options={allFolders.filter(
          (folder) => folder.projectId == appState.projectId
        )}
        value={folder}
        getOptionLabel={(option) => option.name}
        onChange={(a, b, c) => {
          setFolderName("");
          setFolder(b);
          setAppState({
            ...appState,
            folderId: b ? b.id : null,
            fileId: null,
          });
          if (b)
            appState.projects.forEach((k, i) => {
              if (appState.projectId === k.id) {
                setFilePath(`${k.name}/${findPath(k, "id", b.id)}`);
              }
            });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Existing Folder"
            variant="outlined"
          />
        )}
      />
      <MetaWizardUploader
        accepts={accepts}
        folderName={folderName}
        setFolderName={setFolderName}
        setFolder={setFolder}
        setFilePath={setFilePath}
        setAppState={setAppState}
        wizardUploaderFiles={wizardUploaderFiles}
        setWizardUploaderFiles={setWizardUploaderFiles}
        openAccordiansUploader={openAccordiansUploader}
        accordianChange={(item) => {
          if (openAccordiansUploader.includes(item)) {
            openAccordiansUploader = openAccordiansUploader.filter(
              (fil) => fil !== item
            );
          } else {
            openAccordiansUploader.push(item);
          }
        }}
        accordianChange1={(item) => {
          if (!openAccordiansUploader.includes(item)) {
            openAccordiansUploader.push(item);
          }
        }}
        stateChecker={() => {
          // if (currentSection == "upload") {
          //   Sabar("");
          //   Sabar("upload");
          // }
        }}
        failedUploads={failedUploads}
        doneUploads={doneUploads}
        setDoneUploads={setDoneUploads}
        appState={appState}
        allFolders={allFolders}
        filePath={filePath}
        newUploadFiles={newUploadFiles}
        setNewUploadfiles={(newfiles) => {
          setNewUploadfiles(newfiles);
        }}
        updateFunction={(layerResponse, dataObject, newFileName) => {
          let appStateTemp = appState;
          appStateTemp.projects.forEach((project) => {
            if (project.id === dataObject.projectId) {
              insertItemInProject(
                project.children,
                dataObject.parentId,
                layerResponse?.data?.layers[0]
              );
              if (
                dataObject.layerType == 4 &&
                !newFileName.includes(".zip") &&
                !newFileName.includes(".dxf") &&
                !newFileName.includes(".json") &&
                !newFileName.includes(".gpx") &&
                !newFileName.includes(".geojson")
              ) {
                allLayers.push({
                  id: layerResponse?.data?.layers[0].id,
                  name: newFileName,
                  feature_attributes: [],
                  feature_media: [],
                  folderId: layerResponse?.data?.layers[0].folderId,
                  folder_id: layerResponse?.data?.layers[0].folderId,
                  projectId: dataObject.projectId,
                });
                let oldImagery = uploadFilesImagery;
                oldImagery.push({
                  type: 6,
                  id: layerResponse?.data?.layers[0].id,
                  projectId: dataObject.projectId,
                  folderId: layerResponse?.data?.layers[0].folderId,
                  url: layerResponse?.data?.layers[0].signedUrl,
                  show: layerResponse?.data?.layers[0].displayStatus
                    ? true
                    : false,
                });
                setUploadFilesImagery(oldImagery);
                // pendingImagery.push(layerResponse?.data?.layers[0].id);
              } else {
                pendingImagery.push(layerResponse?.data?.layers[0].id);
              }
            }
          });
          setAppState({
            ...appStateTemp,
            //   projectId: cloneprojid,
            //   folderId: clonefolderid,
          });
        }}
      />
    </div>
  );
}
